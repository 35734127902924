











































import EnumerationForm from '@/components/enumeration/EnumerationForm.vue';
import EnumerationUtils, { Enumeration } from '@/utils/EnumerationUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { EnumerationForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<Enumeration>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    try {
      const item = await EnumerationUtils.api.get(
        parseInt(this.$route.params?.id)
      );
      this.item = EnumerationUtils.enumeration.generateDefault(item);
      (this.$refs.form as any).resetValidation();
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = await EnumerationUtils.api.update(this.item);
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [this.$tc('enumeration.label')]),
            type: 'success',
          });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.edit', [this.$tc('enumeration.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },

    async handleDelete() {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await EnumerationUtils.api.remove(parseInt(this.$route.params?.id));
        this.$toast.push({
          text: this.$t('success.delete', [this.$tc('enumeration.label')]),
          type: 'success',
        });
        this.$router.replace({ name: 'enumeration-list' });
      } catch (error: any) {
        this.$toast.push({
          text: this.$t('error.delete', [this.$tc('enumeration.label')]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },
  },
});
